@import "~@ctrip/flight-online-style/specifications/variable";
@import "~@ctrip/flight-online-style/specifications/bem";

$namespace: 'list';

@include b(army-alert) {
  margin-top: 4px;
  width: 100%;
  background-color: #fff9ed;
  border-radius: 2px;
  display: flex;
  align-items: center;
  height: 46px;

  @include e(icon) {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 16px;
  }

  @include e(message) {
    color: #666666;
    font-family: PingFangSC-Regular, $ffBase;
    font-size: 14px;
    font-weight: normal;
    height: 22px;
    letter-spacing: 0.2px;
    line-height: 22px;
  }
}
